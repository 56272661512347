/* eslint-disable jsx-a11y/anchor-is-valid */
import Link from 'next/link';
import type { FC } from 'react';
import { Flex, MaterialIcon, Text } from '@talent-garden/react-components';
import { Image } from '@/components/image';
import styles from './footer.module.css';

export const Footer: FC = () => {
  return (
    <div className={styles.footer}>
      <div className={styles.details}>
        <Link
          passHref
          itemProp="url"
          href="https://talentgarden.org/en"
          rel="noreferrer"
          className={styles.logoLink}
        >
          <a target="_blank">
            <Image
              src="/images/logo_white.png"
              alt="Talent Garden logo"
              className={styles.logo}
              width="200px"
              height="100px"
            />
          </a>
        </Link>
        <Flex>
          <Text variant="s" weight="bold">
            TALENT GARDEN S.P.A.
          </Text>
          <Text variant="s" maxWidth="35%">
            {'Piazza Città di Lombardia n. 1 20124\nMilan, Italy '}
          </Text>
        </Flex>
        <div className={styles.socialShare}>
          <ul className="">
            <li>
              <Link
                passHref
                href="https://www.facebook.com/talentgarden"
                rel="noreferrer"
              >
                <a target="_blank">
                  <MaterialIcon name="facebook" />
                </a>
              </Link>
            </li>
            <li>
              <Link
                passHref
                href="https://www.instagram.com/talentgarden/"
                rel="noreferrer"
              >
                <a target="_blank">
                  <MaterialIcon name="instagram" />
                </a>
              </Link>
            </li>
            <li>
              <Link
                passHref
                href="https://www.linkedin.com/company/talent-garden"
                rel="noreferrer"
              >
                <a target="_blank">
                  <MaterialIcon name="linked_in" />
                </a>
              </Link>
            </li>
            <li>
              <Link
                passHref
                href="https://twitter.com/TalentGardenen"
                rel="noreferrer"
              >
                <a target="_blank">
                  <MaterialIcon name="twitter" />
                </a>
              </Link>
            </li>
            <li>
              <Link
                passHref
                href="https://www.youtube.com/c/TalentGardenOfficial"
                rel="noreferrer"
              >
                <a target="_blank">
                  <MaterialIcon name="you_tube" />
                </a>
              </Link>
            </li>
          </ul>
        </div>
      </div>

      <Flex className={styles.disclaimer} direction="column">
        <span>
          {`©${new Date().getFullYear()} Talent Garden S.p.A. - Nominal Capital €492.354 - REA MI-2099746 - P.IVA/VAT IT03340710981 | `}
        </span>
        <Flex direction="row">
          <Link href="https://talentgarden.org/en/privacy-policy/">
            <a target="_blank" className={styles.link}>
              &nbsp;Privacy Policy&nbsp;
            </a>
          </Link>
          -
          <Link href="https://talentgarden.org/en/cookie-policy/">
            <a target="_blank" className={styles.link}>
              &nbsp;Cookie Policy
            </a>
          </Link>
        </Flex>
      </Flex>
    </div>
  );
};

export default Footer;
