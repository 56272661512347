import React from 'react';
import type { GetServerSideProps } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { Flex } from '@talent-garden/react-components';
import type { NextPageWithLayout } from '@/types/next';
import { loginPageAuthCheck } from '@/lib/auth';
import { Hero } from '@/components/hero';
import { Footer } from '@/components/footer';
import { Meta } from '@/components/meta';

import { handleLanguageDetection } from '@/lib/utils';
import { globalVariable } from '@/lib/globals';
import styles from '../styles/landing.module.css';

const Login: NextPageWithLayout = () => {
  return (
    <Flex className={styles.landing}>
      <Meta />
      <Hero theme={globalVariable?.theme} />
      {globalVariable?.theme === 'tag' ? <Footer /> : null}
    </Flex>
  );
};

export const getServerSideProps: GetServerSideProps = loginPageAuthCheck(
  async (context) => {
    const {
      locale = 'en',
      req,
      locales = [],
      defaultLocale,
      resolvedUrl,
    } = context;

    const languageRedirect = handleLanguageDetection({
      headers: req?.headers,
      resolvedUrl,
      defaultLocale,
      locales,
      locale,
    });

    if (languageRedirect) {
      return languageRedirect;
    }

    return {
      props: {
        ...(await serverSideTranslations(locale, ['common'])),
      },
    };
  },
);

Login.getLayout = (page: React.ReactNode) => page;

export default Login;
