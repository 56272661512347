import React from 'react';
import type { ImageProps } from 'next/image';
import Img from 'next/image';

export const Image = (props: ImageProps) => {
  const { src } = props;
  if (!src) return null;

  return <Img {...props} />;
};
