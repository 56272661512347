import { useQuery } from '@tanstack/react-query';
import type { Session } from 'next-auth';
import { signIn, signOut, useSession } from 'next-auth/react';
import { useCallback, useEffect, useMemo } from 'react';
import { useCookie } from 'react-use';
import { fetchClient } from '@/lib/fetchClient';
import type { Course } from '@/types/course';
import { globalVariable } from '@/lib/globals';

export type Organization = {
  name: string;
  id: string;
  address?: string | null;
  billing_email?: string | null;
  city?: string | null;
  code_sdi?: string | null;
  country?: string | null;
  fiscal_code?: string | null;
  hubspot_company_id?: string | null;
  linkedin_url?: string | null;
  netsuite_id?: string | null;
  pec_email?: string | null;
  phone_number?: string | null;
  phone_prefix?: string | null;
  postal_code?: string | null;
  state?: string | null;
  vat?: string | null;
  hs_object_type?: string | null;
};

export type NetworkItem = {
  id: number | string;
  name: string;
  category_id?: number;
  category_name?: string;
  showAllButton?: boolean;
};

export type UserInfo = {
  id: number;
  full_name?: string | null;
  profile_image_url?: string;
  completed_profile_at?: string;
  city?: string | null;
  job_title?: string | null;

  name?: string;
  orgs?: Organization[];
  auth0_id?: string;
  email?: string | null;
  first_name?: string | null;
  last_name?: string | null;
  bio?: string | null;
  skills?: NetworkItem[] | null;
  opportunities?: NetworkItem[] | null;
  interests?: NetworkItem[] | null;
  needs?: NetworkItem[] | null;
  linkedin_profile_url?: string | null;
  languages?: string[] | null;
  network_organization_id?: number | string | null;
  type?: string | null;
  is_available_for_talks?: boolean;
  phone_number?: string | null;
  phone_prefix?: string | null;
  company?: string;
};

export interface ISession extends Session {
  accessToken?: string;
  courses?: Course[];
  meOn360?: boolean;
  isStudent?: boolean;
}

type UseAuth = {
  signIn(): void;
  signOut(): void;
  session: ISession | null;
  userInfo: UserInfo;
  selectedOrg: Organization;
  userId: string | null;
  isLoadingUserInfo: boolean;
  isFetchingUserInfo: boolean;
  courses?: Course[];
  setSelectOrganizationById: (id: string) => void;
};

export const useAuth = (): UseAuth => {
  const { data: session } = useSession();
  const [selectedOrgFromCookie, setSelectedOrgFromCookie] =
    useCookie('selected-org');
  const [userId, setUserId] = useCookie('user-id');
  // Get QueryClient from the context
  // const queryClient = useQueryClient()

  const selectedOrg = useMemo(() => {
    return JSON.parse(decodeURIComponent(selectedOrgFromCookie as string));
  }, [selectedOrgFromCookie]);

  const result = useQuery(
    ['user-data'],
    async () => {
      try {
        return await fetchClient('/api/me');
      } catch (error) {
        // if(session) signOut()
        return null;
      }
    },
    {
      enabled: Boolean(session && (session as ISession)?.accessToken),
    },
  );

  const userInfo = useMemo(() => {
    return session ? result?.data?.data : null;
  }, [session, result]);

  const setSelectOrganization = useCallback(
    (org: Organization, forceReload = true) => {
      if (!org) {
        return;
      }
      setSelectedOrgFromCookie(String(JSON.stringify(org)), { expires: 7 });
      if (forceReload) window.location.reload();
    },
    [setSelectedOrgFromCookie],
  );

  const setSelectOrganizationById = useCallback(
    (id: string) => {
      if (!userInfo) {
        return;
      }
      if (!id) {
        return;
      }

      const selectedOrg = userInfo?.orgs?.find(
        (org: Organization) => org?.id?.toString() === id?.toString(),
      );

      if (!selectedOrg) {
        return;
      }
      setSelectOrganization(selectedOrg, false);
    },
    [userInfo?.orgs],
  );

  useEffect(() => {
    if (selectedOrg || !userInfo) {
      return;
    }

    setUserId(userInfo?.id);
    const [firstOrg] = userInfo?.orgs || [];

    // default selection
    if (firstOrg) {
      setSelectOrganization(firstOrg, false);
    }
  }, [setSelectOrganization, setUserId, userInfo, selectedOrg]);

  return {
    session: session as ISession,
    signIn: () => signIn('auth0'),
    userInfo,
    signOut,
    selectedOrg,
    setSelectOrganizationById,
    userId,
    isLoadingUserInfo: result.isLoading,
    isFetchingUserInfo: result.isFetching,
    courses: (session as ISession)?.courses
      ? [...((session as ISession)?.courses as Course[])]
      : [],
  };
};
