import type { FC } from 'react';
import React from 'react';
import {
  Button,
  Container,
  Flex,
  Heading,
  Text,
} from '@talent-garden/react-components';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { Image } from '@/components/image';
import type { GlobalVariableProps } from '@/lib/globals';
import { useAuth } from '../../hooks/useAuth';

import styles from './hero.module.css';

export const Hero: FC<{ theme: GlobalVariableProps['theme'] }> = ({
  theme,
}) => {
  const { signIn, signOut, session } = useAuth();
  const { t } = useTranslation('common');

  const getLogo = () => {
    let logo = '/images/logo-tag.svg';
    if (theme === 'hi') {
      logo = '/images/logo-hi-small.svg';
    }
    if (theme === 'tsh') {
      logo = '/images/logo-tsh-small.svg';
    }
    if (theme === 'hypertsh') {
      logo = '/images/logo-hypertsh.svg';
    }

    return logo;
  };

  const getImageDescription = () => {
    let description = 'Talent Garden learn logo';
    if (theme === 'hi') {
      description = 'Hyper Island learn logo';
    }
    if (theme === 'tsh') {
      description = 'The Social Hub logo';
    }
    if (theme === 'hypertsh') {
      description = 'The Social Hub and Hyper Island logos';
    }

    return description;
  };

  const getLogoWidth = () => {
    if (theme === 'hypertsh') {
      return 190;
    }
    if (theme === 'tsh') {
      return 60;
    }
    return 142;
  };

  return (
    <Container>
      <Flex className={styles.ManagerAdmin}>
        <section className={styles.section}>
          <Flex className={styles.card}>
            <Link passHref href="/">
              <Flex className={styles.logoContainer} horizontalAlign="start">
                <Image
                  data-testid="logo-element"
                  src={getLogo()}
                  alt={getImageDescription()}
                  width={getLogoWidth()}
                  height={60}
                />
              </Flex>
            </Link>
            <Heading as="h1" variant="l" className={styles.title}>
              {t('landing-page-title')}
            </Heading>
            <Text variant="m" className={styles.description} maxWidth="362px">
              {t('landing-page-description')}
            </Text>
            <Flex className={styles.buttons}>
              {session ? (
                <Button
                  as="button"
                  capitalize
                  dimension="large"
                  kind="primary"
                  className={styles.button}
                  onClick={() => signOut()}
                >
                  Logout
                </Button>
              ) : (
                <Button
                  as="button"
                  capitalize
                  dimension="large"
                  kind="primary"
                  className={styles.button}
                  onClick={() => signIn()}
                >
                  Login
                </Button>
              )}
            </Flex>
          </Flex>
          <Flex className={styles.card}>
            <Image
              src="/images/main-image.png"
              alt="Main with three people"
              width={490}
              height={490}
            />
          </Flex>
        </section>
      </Flex>
    </Container>
  );
};
